import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useGetAllProductCategoriesQuery } from "@states/product/product-category.api";
import { Toast } from "@helpers/popups/Toast";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { SALE_TARGET } from "@utils/constants/sale-target.constants";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import CustomDateField from "@components/form/CustomDateField";
import CustomInputField from "@components/form/CustomInputField";
import PageWrapper from "@components/layouts/PageWrapper";
import Modal from "react-modal";
import DataTable from "react-data-table-component";
import customStyles from "@components/modals/styles/customStyles";
import usePagination from "@hooks/usePagination";
import useSearch from "@hooks/useSearch";
import { useGetAllWayUserAssignsQuery } from "@states/distribution-region/way-user-assign.api";
import {
  useDeleteProductMutation,
  useGetAllProductsQuery,
  useLazyGetAllProductsQuery,
} from "@states/product/product.api";
import Select, { MultiValue } from "react-select";

interface FormValues {
  user_id: string;
  start_date: Date;
  end_date: Date;
  region_name: string;
  total_sale_target_amount: string;
  expense_percentage: string;
  expense_amount: string;
  remark: string;
  description: string;
}

interface OptionType {
  label: string;
  value: string | number; // Adjust this based on your data
}

interface ProductCategory {
  product_category_name: string;
}

const SaleTargetProduct: React.FC = () => {
  const { page, limit, handlePageChange, handlePerRowsChange } =
    usePagination();
  const [regionList, setRegionList] = useState("");
  const [assignName, setAssignName] = useState("");
  const [productCategoryName, setProductCategoryName] = useState("");
  const { search, setSearch } = useSearch();
  const [target, setTarget] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenProduct, setIsOpenProduct] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [productDetail, setProductDetail] = useState();
  const [selectedType, setSelectedType] = useState("2");
  const [saleTargetAmounts, setSaleTargetAmounts] = useState({});
  const [quantities, setQuantities] = useState({});
  const [totalSalesTargetAmount, setTotalSalesTargetAmount] = useState(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm();
  const handleClick = () => {
    setIsOpen(true);
  };
  const { data: productCategoryData, isLoading: productCategoryLoading } =
    useGetAllProductCategoriesQuery({
      page,
      limit,
      product_name: search,
    });
  const {
    data: wayData,
    isFetching,
    error,
    refetch,
  } = useGetAllWayUserAssignsQuery({
    page,
    limit,
    username: search,
  });

  //@ts-ignore
  const categoryOptions = productCategoryData?.data?.map((o) => ({
    label: o.product_category_name,
    value: o.product_category_id,
  }));

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);
  const handleModalClose = () => setIsOpen(false);
  const handleRowClick = (row: any) => {
    const wayNames =
      row.way_list?.map((way: any) => way.way_name).join(", ") || "";
    setRegionList(wayNames);
    setAssignName(row.username);
    setValue("user_region_id", row.user_id);
    handleModalClose();
  };

  const HandleNextPage = () => {
    setNextPage((prev) => !prev);
  };
  const [getproductList, { data: productData, isLoading: productLoading }] =
    useLazyGetAllProductsQuery();

  // useEffect(() => {
  //   getproductList({
  //     product_category_ids: "43",
  //   });
  // }, []);

  // console.log("Product Data", productData);
  // console.log("Cat", categoryOptions);

  const onClickProductTargetHandler = (row: any) => {
    getproductList({
      product_category_id: row.product_category_id,
    });
    setIsOpenProduct(false);
    setProductCategoryName(row.product_category_name);
  };
  const handleProductTargetClick = () => {
    setIsOpenProduct(true);
  };

  const [selectedValues, setSelectedValues] = useState<(string | number)[]>([]);

  // Handle change for multi-select
  const handleChange = (newValue: MultiValue<OptionType>) => {
    // Map selected options to their values
    const values = newValue ? newValue.map((option) => option.value) : [];
    console.log("Seect", values.toString());
    getproductList({
      product_category_ids: values.toString(),
    });
    setSelectedValues(values); // Update the state with selected values
    console.log("Selected Values:", values);
  };

  // Calculate total sales target amount whenever saleTargetAmounts state changes
  useEffect(() => {
    const total = Object.values(saleTargetAmounts).reduce(
      (acc: any, curr: any) => acc + (parseFloat(curr) || 0),
      0
    );
    //@ts-ignore
    setTotalSalesTargetAmount(total);
    setValue("total_sales_target_amount", total);
    // Ensure allow_expense_percentage is a number
    const allow_expense_percentage = parseFloat(
      getValues().allow_expense_percentage
    );

    // Calculate expense_amount
    // @ts-ignore
    const expense_amount = (allow_expense_percentage / 100) * total;

    // Round the expense_amount and set the value
    setValue("expense_amount", Math.round(expense_amount) || 0);
  }, [saleTargetAmounts]);

  const handleExpense = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allow_expense_percentage_value: any = event.target.value;
    console.log("total_sales_target_amount");
    let total_sales_target_amount = getValues().total_sales_target_amount;
    const expense_amount =
      (parseInt(allow_expense_percentage_value) / 100) *
      parseInt(total_sales_target_amount);
    setValue("expense_amount", expense_amount);
  };

  const handleInputChange = (e: any, row: any) => {
    const { value } = e.target;
    const price = parseFloat(value) || 0;
    // @ts-ignore
    const qty = quantities[row.product_id] || 1;
    const cal_product_price_qty = price * qty;

    setSaleTargetAmounts((prev) => ({
      ...prev,
      [row.product_id]: cal_product_price_qty,
    }));
  };
  const handleSelectRow = (row: any) => {
    //@ts-ignore
    if (selectedRows.includes(row)) {
      // @ts-ignore
      setSelectedRows(selectedRows.filter((r) => r !== row));
    } else {
      // @ts-ignore
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectAllRows = (rows: any) => {
    // @ts-ignore
    if (selectedRows.length === rows.length) {
      // @ts-ignore
      setSelectedRows([]);
    } else {
      // @ts-ignore
      setSelectedRows(rows);
    }
  };
  const handleInputChangeQty = (e: any, row: any) => {
    const { value } = e.target;
    const qty = parseInt(value) || 0;
    const cal_product_price_qty = qty * row.secondary_sales_price;

    setQuantities((prev) => ({ ...prev, [row.product_id]: qty }));
    setSaleTargetAmounts((prev) => ({
      ...prev,
      [row.product_id]: cal_product_price_qty,
    }));
  };
  const handleButtonClick = () => {
    // Perform action on selected rows
    console.log("Selected Rows:", selectedRows);
    setAssignName(`${selectedRows.length} selected`);
    handleModalClose();
    // Example: send selected rows to an API or update state
  };

  const onSubmit = async (data: any) => {
    // Prepare updated productData with sale_target_amounts
    if (selectedType == "1") {
      // @ts-ignore
      const updatedProductData = productData?.data.map((row) => ({
        product_id: row.product_id,
        sale_price: row.secondary_sales_price,
        // @ts-ignore
        // sales_target_amount: saleTargetAmounts[row.product_id] || 0,
        //@ts-ignore
        sales_target_qty: quantities[row.product_id] || 0,
      }));
      data.sales_target_detail = updatedProductData;
    } else if (selectedType == "2") {
      // @ts-ignore
      const updatedProductData = productData?.data.map((row) => ({
        product_id: row.product_id,
        // @ts-ignore
        sales_target_amount: saleTargetAmounts[row.product_id] || 0,
        //@ts-ignore
        // sales_target_qty: quantities[row.product_id] || 0,
      }));
      data.sales_target_detail = updatedProductData;
    }

    data.sales_target_type_id = "2";
    data.target_category = selectedType;
    data.product_category_id = JSON.stringify(selectedValues);
    const url = "sale-target/product-target";
    data.user_region_list = selectedRows;

    console.log("Data", data);

    try {
      const response = await fetch(`${getBaseUrl()}${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const res = await response.json();
      const { success, message } = res as TSuccessResponse;

      if (success) {
        await Toast.fire({
          title: message,
          icon: "success",
        });

        navigate(`${SALE_TARGET.BASE_PATH}product-target`, {
          state: {
            reload: true,
          },
        });
      } else {
        await Toast.fire({ title: res.message, icon: "error" });
      }
    } catch (error) {
      await Toast.fire({ title: "An error occurred", icon: "error" });
    }
  };
  const HandleTargetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
    setValue("expense_amount", 0);
    setValue("total_sales_target_amount", 0);
    setValue("allow_expense_percentage", 0);
  };

  return (
    <PageWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <div className="grid grid-cols-6 gap-y-6">
            <div
              className="flex flex-col p-2 undefined"
              style={{ gridColumn: "auto / span 2" }}
            >
              <h3>Sale Target By</h3>
              <div className="flex gap-5 mt-5">
                <div className=" flex justify-start gap-2 items-center">
                  <input
                    type="radio"
                    name="type"
                    value="1"
                    id="quantity"
                    checked={selectedType == "1"}
                    onChange={HandleTargetChange}
                  />
                  <label htmlFor="quantity">Quantity</label>
                </div>
                <div className=" flex justify-start gap-2 items-center">
                  <input
                    type="radio"
                    name="type"
                    value="2"
                    checked={selectedType == "2"}
                    id="amount"
                    onChange={HandleTargetChange}
                  />
                  <label htmlFor="amount">Amount</label>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col p-2 undefined"
              style={{ gridColumn: "auto / span 2" }}
            >
              <label
                className="mb-2 text-gray-700"
                style={{ textTransform: "capitalize" }}
              >
                Assignee Name
              </label>
              <label
                onClick={handleClick}
                className="relative block text-gray-400 cursor-pointer focus-within:text-gray-600"
              >
                <input
                  type="text"
                  name="Search"
                  id="search"
                  className="px-2 cursor-pointer py-3 w-full border-[0.5px] focus:ring-1 ring-primary focus:outline-none rounded border-primary-light"
                  placeholder="Choose Assignee Name"
                  value={assignName}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="currentColor"
                  className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                >
                  <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"></path>
                </svg>
              </label>
            </div>
            {/* <div className="undefined flex flex-col p-2 undefined" style={{ gridColumn: " auto / span 2" }}>
                <label className="mb-2 text-gray-700" style={{ textTransform: "capitalize" }}>Region Name</label>
                <input type="text" className="px-2 py-3 border-[0.7px] focus:ring-1 bg-zinc-100 cursor-not-allowed ring-primary focus:outline-none rounded border-primary-light" placeholder="Enter region name" value={regionList} />
              </div> */}

            <CustomDateField
              key="start_date"
              colSpan={2}
              errors={errors}
              name="start_date"
              register={register}
              control={control}
              label="Start Date"
              required
            />
            <CustomDateField
              key="end_date"
              colSpan={2}
              errors={errors}
              name="end_date"
              register={register}
              control={control}
              label="End Date"
              required
            />

            <div
              className="flex flex-col p-2"
              style={{ gridColumn: "auto / span 2" }}
            >
              <label
                className="mb-2 text-gray-700"
                style={{ textTransform: "capitalize" }}
              >
                Product Category
              </label>

              <div>
                <Select
                  isMulti={true} // Enable multi-select
                  options={categoryOptions} // Options for the select
                  value={
                    //@ts-ignore
                    categoryOptions?.filter((option) =>
                      selectedValues.includes(option.value)
                    ) || []
                  } // Filter based on selected values
                  onChange={handleChange} // Handle multi-select change
                  menuPortalTarget={document.body} // Optional: To render the menu in a different part of the DOM
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      paddingTop: "0.19rem",
                      paddingBottom: "0.19rem",
                      borderColor: "rgb(128,149,187)",
                      borderWidth: "0.5px",
                    }),
                    option: (base, { isFocused, isSelected }) => ({
                      ...base,
                      backgroundColor: isSelected
                        ? "rgb(45,120,219)"
                        : isFocused
                        ? "#e5e7eb"
                        : "",
                      color: isSelected ? "#ffffff" : "",
                    }),
                  }}
                />
              </div>
            </div>

            {/* <div
              className="flex flex-col p-2"
              style={{ gridColumn: "auto / span 2" }}
            >
              <label
                className="mb-2 text-gray-700"
                style={{ textTransform: "capitalize" }}
              >
                Product Category
              </label>
              <label
                onClick={handleProductTargetClick}
                className="relative block text-gray-400 cursor-pointer focus-within:text-gray-600"
              >
                <input
                  type="text"
                  name="Search"
                  id="search"
                  className="px-2 cursor-pointer py-3 w-full border-[0.5px] focus:ring-1 ring-primary focus:outline-none rounded border-primary-light"
                  placeholder="Choose Product Category"
                  value={productCategoryName}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="currentColor"
                  className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                >
                  <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"></path>
                </svg>
              </label>
            </div> */}
            <CustomInputField
              errors={errors}
              colSpan={2}
              key="total_sales_target_amount"
              placeHolder="Enter Total Sale Target Amount"
              register={register}
              disabled
              name="total_sales_target_amount"
              inputType="text"
              label="Total Sale Target Amount"
            />
            <div
              className="undefined flex flex-col p-2 undefined"
              style={{ gridColumn: " auto / span 2" }}
            >
              <label
                className="mb-2 text-gray-700"
                style={{ textTransform: "capitalize" }}
              >
                Expense Percentage
              </label>
              <input
                {...register("allow_expense_percentage")}
                type="text"
                className="px-2 py-3 border-[0.7px] focus:ring-1   ring-primary focus:outline-none rounded border-primary-light"
                placeholder="Enter Expense Percentage"
                onChange={handleExpense}
              />
            </div>
            <CustomInputField
              errors={errors}
              colSpan={2}
              key="expense_amount"
              placeHolder="Enter Expense Amount"
              register={register}
              name="expense_amount"
              inputType="text"
              disabled
              label="Expense Amount"
            />
          </div>

          <div className="grid grid-cols my-10">
            <div className="flex flex-col p-2">
              <label
                className="mb-2 text-gray-700"
                style={{ textTransform: "capitalize" }}
                htmlFor="description"
              >
                Description
              </label>
              <textarea
                {...register("description")}
                rows={3}
                cols={1}
                className="px-2 py-3 border-[0.7px] focus:ring-1  ring-primary focus:outline-none rounded border-primary-light"
                placeholder="Enter description"
              ></textarea>
            </div>
          </div>
          <div className="flex items-center justify-between my-5">
            <h3 className="text-xl font-[600] uppercase text-primary-dark">
              Product Detail List
            </h3>
            <div className="flex w-[33%] items-center justify-center">
              <div className="w-full">
                <label className="relative block text-gray-400 focus-within:text-gray-600">
                  <input
                    onChange={handleSearchChange}
                    type="text"
                    name="Search"
                    id="search"
                    placeholder="Search..."
                    className="block w-full px-4 py-[0.65rem] text-[0.80rem] text-gray-500 placeholder-gray-400 bg-white rounded shadow-sm border-[0.7px] border-primary-light form-input focus:outline-none"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="currentColor"
                    className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                  >
                    <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"></path>
                  </svg>
                </label>
              </div>
            </div>
          </div>

          <DataTable
            columns={[
              {
                name: "No",
                selector: (row: any) => row.product_id,
              },
              {
                name: "Product Name",
                selector: (row: any) => row.product_name,
              },
              {
                name: "Product Category Name",
                selector: (row: any) => row.product_category_name,
              },
              {
                name: "Sale Target Qty",
                cell: (row) => (
                  <input
                    type="number"
                    className="px-2 py-1 border rounded"
                    onChange={(e) => handleInputChangeQty(e, row)}
                    placeholder="Enter Sale Target Qty"
                    value={selectedType == "2" ? 0 : undefined} // Set value to 0 if disabled
                    // @ts-ignore
                    defaultValue={0}
                    required
                    disabled={selectedType == "2"}
                  />
                ),
              },
              {
                name: "Sale Target Amount",
                cell: (row) => (
                  <input
                    type="number"
                    className="px-2 py-1 border rounded"
                    onChange={(e) => handleInputChange(e, row)}
                    placeholder="Enter Sale Target"
                    value={selectedType == "1" ? 0 : undefined} // Set value to 0 if disabled
                    defaultValue={0}
                    required
                    disabled={selectedType == "1"}
                  />
                ),
              },
            ]}
            // @ts-ignore
            data={productData?.data}
            striped
            fixedHeader
            fixedHeaderScrollHeight="370px"
            // pagination
            // paginationServer
            // @ts-ignore
            // paginationTotalRows={productData?.total}
            // progressPending={isFetching}
            noDataComponent="No Product List found"
            // @ts-ignore
            // onChangePage={handlePageChange}
            // onChangeRowsPerPage={handlePerRowsChange}
            persistTableHead
          />
          <div className="ml-2 mt-14">
            <button
              type="button"
              className="text-gray-800 px-28 py-4 rounded text-sm outline outline-0.5 outline-gray-300 mr-10"
              onClick={HandleNextPage}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-primary text-white px-28 py-4 rounded text-sm outline outline-0.5 outline-gray-300 mr-10"
            >
              Submit
            </button>
          </div>
        </>
      </form>
      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={isOpen}
        contentLabel="Assignee List"
      >
        <div className="relative h-full">
          <div className="flex justify-end">
            <svg
              onClick={handleModalClose}
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#3e3e3e"
              className="cursor-pointer"
            >
              <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
            </svg>
          </div>
          <div className="py-4 px-14">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-xl font-[600] uppercase text-primary-dark">
                Assignee List
              </h3>
              <div className="flex w-[33%] items-center justify-center">
                <div className="w-full">
                  <label className="relative block text-gray-400 focus-within:text-gray-600">
                    <input
                      onChange={handleSearchChange}
                      type="text"
                      name="Search"
                      id="search"
                      placeholder="Search..."
                      className="block w-full px-4 py-[0.65rem] text-[0.80rem] text-gray-500 placeholder-gray-400 bg-white rounded shadow-sm border-[0.7px] border-primary-light form-input focus:outline-none"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="currentColor"
                      className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                    >
                      <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"></path>
                    </svg>
                  </label>
                </div>
              </div>
            </div>
            <div className="h-[410px] overflow-auto">
              <DataTable
                columns={[
                  {
                    name: (
                      <input
                        type="checkbox"
                        // @ts-ignore
                        onChange={() => handleSelectAllRows(wayData?.data)}
                        // @ts-ignore
                        checked={selectedRows.length === wayData?.data.length}
                      />
                    ),
                    // @ts-ignore
                    selector: (row: any) => (
                      <input
                        type="checkbox"
                        // @ts-ignore
                        checked={selectedRows.includes(row)}
                        onChange={() => handleSelectRow(row)}
                      />
                    ),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                  },
                  {
                    name: "Assignee Name",
                    selector: (row: any) => row.username,
                  },

                  // {
                  //   name: "Action",
                  //   // @ts-ignore
                  //   selector: (row: any) => (
                  //     <button
                  //       onClick={() => handleRowClick(row)}
                  //       className="px-3 py-1 text-sm text-gray-100 rounded bg-primary-dark"
                  //     >
                  //       Choose
                  //     </button>
                  //   ),
                  // },
                ]}
                // @ts-ignore
                data={wayData?.data}
                striped
                pagination
                paginationServer
                // @ts-ignore
                paginationTotalRows={wayData?.total}
                progressPending={isFetching}
                noDataComponent="No assignees found"
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
              />
            </div>
            <p
              className=" bg-primary-dark flex justify-end float-right cursor-pointer text-center mt-10 text-white py-2 px-3 rounded text-sm outline outline-[0.5px] outline-gray-300 undefined"
              onClick={handleButtonClick}
            >
              Add
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={isOpenProduct}
        contentLabel="Example Modal"
      >
        <div className="relative h-full">
          <div className="flex justify-end">
            <svg
              onClick={() => setIsOpenProduct(false)}
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#3e3e3e"
              className="cursor-pointer"
            >
              <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
            </svg>
          </div>
          <div className="py-4 px-14">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-xl font-[600] uppercase text-primary-dark">
                Product Categroy List
              </h3>
              <div className="flex w-[33%] items-center justify-center ">
                <div className="w-full">
                  <label className="relative block text-gray-400 focus-within:text-gray-600">
                    <input
                      // onChange={searchAssignName}
                      onChange={handleSearchChange}
                      type="text"
                      name="Search"
                      id="search"
                      placeholder="Search..."
                      className="block w-full px-4 py-[0.65rem] text-[0.80rem] text-gray-500 placeholder-gray-400 bg-white rounded shadow-sm border-[0.7px] border-primary-light form-input focus:outline-none"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="currentColor"
                      className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                    >
                      <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
                    </svg>
                  </label>
                </div>
              </div>
            </div>
            <div className="h-[410px] overflow-auto">
              <DataTable
                columns={[
                  {
                    name: "Product Categroy Name",
                    selector: (row: any) => row.product_category_name,
                  },
                  {
                    name: "Action",
                    selector: (row: any) => (
                      <button
                        onClick={() => onClickProductTargetHandler(row)}
                        className="px-3 py-1 text-sm text-gray-100 rounded bg-primary-dark"
                      >
                        Choose
                      </button>
                    ),
                  },
                ]}
                // @ts-ignore
                data={productCategoryData?.data}
                striped
                pagination
                paginationServer
                //@ts-ignore
                paginationTotalRows={productCategoryData?.total} // If you have total count from the API response
                progressPending={isFetching}
                noDataComponent="No assignees found"
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
              />
            </div>
          </div>
        </div>
      </Modal>
    </PageWrapper>
  );
};

export default SaleTargetProduct;
