export function formatDBDate(date: Date) {
    // Get the year, month, and day
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
    let day = ('0' + date.getDate()).slice(-2);

    // Concatenate them in the desired format
    let formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}
