import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { SECONDARY_SALE_ORDER } from "@utils/constants/secondary-sale-order.constant";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const secondarySaleOrderApi = createApi({
  reducerPath: "secondarySaleOrderApi",
  tagTypes: ["SecondarySaleOrder"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllSecondarySaleOrders: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        customer_name?: string;
        secondary_sales_order_code?: string;
        customer_id?: string | number;
        payment_type_id?: string | number;
        from_date?: Date | string;
        to_date?: Date | string;
        status?: string | number;
        user_id?: string | number;
        user_role_id?: string | number;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        customer_name,
        secondary_sales_order_code,
        customer_id,
        payment_type_id,
        from_date,
        to_date,
        status,
        user_id,
        user_role_id,
      }) => {
        const queryParams = {
          url: SECONDARY_SALE_ORDER.GET_ALL,
          params: { page, limit },
        };

        if (customer_name) {
          //@ts-ignore
          queryParams.params.customer_name = customer_name;
        }

        if (secondary_sales_order_code) {
          //@ts-ignore
          queryParams.params.secondary_sales_order_code =
            secondary_sales_order_code;
        }

        if (customer_id) {
          //@ts-ignore
          queryParams.params.customer_id = customer_id;
        }

        if (payment_type_id) {
          //@ts-ignore
          queryParams.params.payment_type_id = payment_type_id;
        }

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        if (status) {
          //@ts-ignore
          queryParams.params.status = status;
        }

        if (user_id) {
          //@ts-ignore
          queryParams.params.user_id = user_id;
        }

        if (user_role_id) {
          //@ts-ignore
          queryParams.params.user_role_id = user_role_id;
        }

        return queryParams;
      },
    }),
    getSecondarySaleOrder: builder.query<
      any,
      { secondary_sales_order_id: string | number }
    >({
      query: ({ secondary_sales_order_id }) =>
        `${SECONDARY_SALE_ORDER.GET_ONE}${secondary_sales_order_id}`,
    }),
    createSecondarySaleOrder: builder.mutation({
      query: (body) => ({
        url: SECONDARY_SALE_ORDER.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateSecondarySaleOrder: builder.mutation({
      query: ({ id, body }) => ({
        url: `${SECONDARY_SALE_ORDER.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    approveSecondaryOrder: builder.mutation({
      query: ({ id, body }) => ({
        url: `${SECONDARY_SALE_ORDER.APPROVE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteSecondarySaleOrder: builder.mutation({
      query: (id) => ({
        url: `${SECONDARY_SALE_ORDER.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllSecondarySaleOrdersQuery,
  useGetSecondarySaleOrderQuery,
  useApproveSecondaryOrderMutation,
  useCreateSecondarySaleOrderMutation,
  useUpdateSecondarySaleOrderMutation,
  useDeleteSecondarySaleOrderMutation,
} = secondarySaleOrderApi;
