import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

interface CustomTextAreaFieldProps {
    rows?: number;
    cols?: number;
    name: string;
    label?: string;
    disabled?: boolean;
    noLabel?: boolean;
    placeHolder?: string;
    register: UseFormRegister<any>;
    errors: FieldErrors<any>;
    colSpan?: number;
    value?:string
}

const CustomTextAreaField: React.FC<CustomTextAreaFieldProps> = ({
    rows,
    cols,
    name,
    label,
    disabled,
    noLabel,
    placeHolder,
    register,
    errors,
    colSpan,
    value
}) => {
    return (
        <div
            style={{
                gridColumn: `auto / span ${colSpan || '2'}`,
            }}
            className={`flex flex-col p-2`}
        >
            {!noLabel ? (
                <label
                    className="mb-2 text-gray-700"
                    style={{ textTransform: 'capitalize' }}
                    htmlFor="name"
                >
                    {label || name}
                </label>
            ) : null}
            <textarea
                rows={rows || 3}
                cols={cols}
                {...register(name)}
                disabled={disabled ? disabled : false}
                className="px-2 py-3 border-[0.5px] focus:ring-1 ring-primary focus:outline-none  rounded border-primary-light"
                placeholder={
                    placeHolder
                        ? placeHolder
                        : `Enter ${label?.toLowerCase() || name}`
                }
                // value={value ? value : ''}
            ></textarea>
            {errors[name] && (
                <div className="mt-2 font-[300] text-red-600">
                    {/* @ts-ignore */}
                    {errors[name].message}
                </div>
            )}
        </div>
    );
};

export default CustomTextAreaField;
