import CustomButton from "../buttons/CustomButton";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { formatFilterDate } from "@utils/general/format-filter-date";

const SalesByRegionReportFilter = (props: {
  filterRef: React.MutableRefObject<{
    from_date: undefined;
    to_date: undefined;
  }>;
  refetch: () => void;
}) => {
  const { filterRef, refetch } = props;

  const [curFromDate, setCurFromDate] = useState<Date | null | undefined>(null);
  const [curToDate, setCurToDate] = useState<Date | null | undefined>(null);

  const onChangeHandler = (val: any, key: string) => {
    if (key === "from_date" || key === "to_date") {
      filterRef.current = {
        ...filterRef.current,
        [key]: formatFilterDate(val),
      };

      return;
    }
  };

  return (
    <div className="flex items-end mb-5">
      <div className="mr-6 basis-[20%] flex flex-col">
        <label className="text-gray-700 " htmlFor="name">
          From Date
        </label>
        <div
          // onClick={handleClick}
          className="relative block text-gray-400 cursor-pointer focus-within:text-gray-600"
        >
          <DatePicker
            selected={curFromDate}
            wrapperClassName="w-full"
            className="ring-primary px-3 mt-[0.5rem] border-primary-light border-[0.5px] h-[34px] w-full block rounded"
            dateFormat={"dd MMM yyyy"}
            onChange={(val) => {
              setCurFromDate(val);
              onChangeHandler(val, "from_date");
            }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="currentColor"
            className={`absolute w-6 h-6 transform -translate-y-1/3 cursor-pointer top-1/2 right-3`}
          >
            <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
          </svg>
        </div>
      </div>
      <div className="mr-6 basis-[20%] flex flex-col">
        <label className="text-gray-700 " htmlFor="name">
          To Date
        </label>
        <div
          // onClick={handleClick}
          className="relative block text-gray-400 cursor-pointer focus-within:text-gray-600"
        >
          <DatePicker
            selected={curToDate}
            wrapperClassName="w-full"
            className="ring-primary px-3 mt-[0.5rem] border-primary-light border-[0.5px] h-[34px] w-full block rounded"
            dateFormat={"dd MMM yyyy"}
            onChange={(val) => {
              setCurToDate(val);
              onChangeHandler(val, "to_date");
            }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="currentColor"
            className={`absolute w-6 h-6 transform -translate-y-1/3 cursor-pointer top-1/2 right-3`}
          >
            <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
          </svg>
        </div>
      </div>

      <div className="basis-1/6">
        <CustomButton
          handleClick={() => {
            refetch();
          }}
          className="w-24 h-[2.4rem]"
          title="Filter"
        />
      </div>
    </div>
  );
};

export default SalesByRegionReportFilter;
