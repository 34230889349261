import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { SECONDARY_SALES_RETURN } from '@utils/constants/secondary-sales-return/sales-return.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const secondarySalesReturnApi = createApi({
    reducerPath: 'secondarySalesReturnApi',
    tagTypes: ['SecondarySalesReturn'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllSecondarySaleReturns: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                customer_first_name?: string;
                secondary_sales_invoice_code?: string;
                secondary_sales_return_code?: string;
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                customer_first_name,
                secondary_sales_invoice_code,
                secondary_sales_return_code,
            }) => {
                const queryParams = {
                    url: SECONDARY_SALES_RETURN.GET_ALL,
                    params: { page, limit },
                };

                if (customer_first_name) {
                    //@ts-ignore
                    queryParams.params.customer_first_name =
                        customer_first_name;
                }

                if (secondary_sales_invoice_code) {
                    //@ts-ignore
                    queryParams.params.secondary_sales_invoice_code =
                        secondary_sales_invoice_code;
                }

                if (secondary_sales_return_code) {
                    //@ts-ignore
                    queryParams.params.secondary_sales_return_code =
                        secondary_sales_return_code;
                }

                return queryParams;
            },
        }),
        getAllSecondaryMakePayments: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                secondary_sales_return_code?: string;
            }
        >({
            query: ({
                page = 1,
                limit = 10,

                secondary_sales_return_code,
            }) => {
                const queryParams = {
                    url: SECONDARY_SALES_RETURN.GET_ALL_MAKE_PAYMENTS,
                    params: { page, limit },
                };

                if (secondary_sales_return_code) {
                    //@ts-ignore
                    queryParams.params.secondary_sales_return_code =
                        secondary_sales_return_code;
                }

                return queryParams;
            },
        }),
        getSecondarySaleReturn: builder.query<
            any,
            { secondary_sales_return_id: string | number }
        >({
            query: ({ secondary_sales_return_id }) =>
                `${SECONDARY_SALES_RETURN.GET_ONE}${secondary_sales_return_id}`,
        }),
        createSecondarySaleReturn: builder.mutation({
            query: (body) => ({
                url: SECONDARY_SALES_RETURN.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateSecondarySaleReturn: builder.mutation({
            query: ({ id, body }) => ({
                url: `${SECONDARY_SALES_RETURN.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        createSecondaryMakePayment: builder.mutation({
            query: ({ secondary_sales_return_id, body }) => ({
                url: `${SECONDARY_SALES_RETURN.MAKE_PAYMENT}${secondary_sales_return_id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteSecondarySaleReturn: builder.mutation({
            query: ({ id, body }) => ({
                url: `${SECONDARY_SALES_RETURN.DELETE}${id}`,
                method: 'DELETE',
                body,
            }),
        }),
        // deleteSecondaryMakePayment: builder.mutation({
        //     query: ({ secondary_sales_return_payment_made_id }) => ({
        //         url: `${SECONDARY_SALES_RETURN.DELETE_MAKE_PAYMENT}${secondary_sales_return_payment_made_id}`,
        //         method: 'DELETE',
        //     }),
        // }),
        deleteSecondaryMakePayment: builder.mutation({
            query: (id) => ({
                url: `${SECONDARY_SALES_RETURN.DELETE_MAKE_PAYMENT}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllSecondarySaleReturnsQuery,
    useGetSecondarySaleReturnQuery,
    useCreateSecondarySaleReturnMutation,
    useUpdateSecondarySaleReturnMutation,
    useDeleteSecondarySaleReturnMutation,
    useCreateSecondaryMakePaymentMutation,
    useGetAllSecondaryMakePaymentsQuery,
    useDeleteSecondaryMakePaymentMutation,
} = secondarySalesReturnApi;
