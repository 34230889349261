import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { DISTRIBUTION_REGION } from "@utils/constants/distribution-region.constants";
import { TRegion } from "@type-defs/distribution-region/TRegion";

export const distributionRegionApi = createApi({
  reducerPath: "distributionRegionApi",
  tagTypes: ["DistributionRegion", "Region"],
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
  }),
  endpoints: (builder) => ({
    getAllDistributionRegions: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        region_name?: string;
        state_id?: number | string;
      }
    >({
      query: ({ page = 1, limit = 10, region_name, state_id }) => {
        const queryParams = {
          url: DISTRIBUTION_REGION.GET_ALL,
          params: { page, limit },
        };

        if (region_name) {
          //@ts-ignore
          queryParams.params.region_name = region_name;
        }

        if (state_id) {
          //@ts-ignore
          queryParams.params.state_id = state_id;
        }

        return queryParams;
      },
    }),
    getDistributionRegion: builder.query<[], { regionId: string | number }>({
      query: ({ regionId }) => {
        return `${DISTRIBUTION_REGION.GET_ONE}${regionId}`;
      },
    }),
    getAllRegions: builder.query<
      [],
      { page?: number; limit?: number; region_name?: string }
    >({
      query: ({ page = 1, limit = 10, region_name }) => {
        const queryParams = {
          url: DISTRIBUTION_REGION.GET_ALL_REGIONS,
          params: { page, limit },
        };

        if (region_name) {
          //@ts-ignore
          queryParams.params.region_name = region_name;
        }

        return queryParams;
      },
      providesTags: (result, error, arg) => {
        const { success, data } = result as any as TSuccessResponse<TRegion>;

        if (success && data) {
          const ids = data.map(({ region_id }) => ({
            type: "Region" as const,
            id: region_id,
          }));

          return [...ids, { type: "Region" }];
        }

        return [{ type: "Region" }];
      },
    }),
    createDistributionRegion: builder.mutation({
      query: (body) => ({
        url: "distribution-region-management/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["DistributionRegion", "Region"],
    }),
    updateDistributionRegion: builder.mutation({
      query: ({ id, body }) => ({
        url: `distribution-region-management/update/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "DistributionRegion", id: arg.id },
      ],
    }),
    deleteDistributionRegion: builder.mutation({
      query: (id) => ({
        url: `distribution-region-management/delete/${id}`,
        method: "DELETE",
      }),
    }),

    getRegionByWayId: builder.query<[], { way_id?: string | number }>({
      query: ({ way_id }) => {
        return `${DISTRIBUTION_REGION.GET_REGION_BY_WAY_ID}${way_id}`;
      },
    }),
  }),
});

export const {
  useGetAllDistributionRegionsQuery,
  useCreateDistributionRegionMutation,
  useUpdateDistributionRegionMutation,
  useDeleteDistributionRegionMutation,
  useGetAllRegionsQuery,
  useGetDistributionRegionQuery,
  useGetRegionByWayIdQuery,
} = distributionRegionApi;
