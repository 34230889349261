import React, { useCallback, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useGetAllWayUserAssignsQuery } from "@states/distribution-region/way-user-assign.api";
import { Toast } from "@helpers/popups/Toast";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { SALE_TARGET } from "@utils/constants/sale-target.constants";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import CustomDateField from "@components/form/CustomDateField";
import CustomInputField from "@components/form/CustomInputField";
import PageWrapper from "@components/layouts/PageWrapper";
import Modal from "react-modal";
import DataTable from "react-data-table-component";
import customStyles from "@components/modals/styles/customStyles";
import useSearch from "@hooks/useSearch";
import usePagination from "@hooks/usePagination";
import {
  useGetSaleTargetQuery,
  useUpdateSaleTargetMutation,
} from "@states/sale-target/sale-target.api";
import { formatDBDate } from "@utils/general/format-db-date";

interface FormValues {
  user_id: string;
  start_date: Date;
  end_date: Date;
  region_name: string;
  total_sale_target_amount: string;
  expense_percentage: string;
  expense_amount: string;
  remark: string;
  description: string;
}

interface Way {
  way_name: string;
}

interface Row {
  way_list?: Way[];
  username: string;
  user_id: string;
}
interface SaleTargetAllEditProps {
  saleTargetData: any; // Define the correct type based on your data structure
}
const SaleTargetAllEdit: React.FC<SaleTargetAllEditProps> = ({
  saleTargetData,
}) => {
  console.log("saleTargetData");
  console.log(saleTargetData);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [assignName, setAssignName] = useState("");
  const [regionList, setRegionList] = useState("");
  const { search, setSearch } = useSearch();
  const { page, limit, handlePageChange, handlePerRowsChange } =
    usePagination();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm();

  const { data, isFetching, error, refetch } = useGetAllWayUserAssignsQuery({
    page,
    limit,
    username: search,
  });

  const handleModalOpen = () => setIsOpen(true);
  const handleModalClose = () => setIsOpen(false);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);

  const handleRowClick = (row: Row) => {
    const wayNames = row.way_list?.map((way) => way.way_name).join(", ") || "";
    setRegionList(wayNames);
    setAssignName(row.username);
    setValue("user_region_id", row.user_id);
    handleModalClose();
  };
  const [updateSaleTarget, { isLoading, isSuccess, error: createError }] =
    useUpdateSaleTargetMutation();
  const onSubmit = async (data: any) => {
    try {
      // async stuff here
      console.log(data, "data");
      await asyncDispatcher(data);
    } catch (error: any) {
      setError("root", {
        message: error.message,
      });
    }
  };
  const asyncDispatcher = useCallback(
    async (reqBody: any) => {
      try {
        const { ...rest } = reqBody;
        const id = saleTargetData.sales_target_id;
        const sales_target_type_id = saleTargetData.sales_target_type_id;
        rest.start_date = rest.start_date
          ? formatDBDate(rest.start_date)
          : null;
        rest.end_date = rest.end_date ? formatDBDate(rest.end_date) : null;
        const res = await updateSaleTarget({
          id,
          body: {
            sales_target_type_id: sales_target_type_id,
            ...rest,
          },
        });

        const successData = "data" in res ? res.data : null;
        const { success, message } = successData as TSuccessResponse;

        if (success || isSuccess) {
          await Toast.fire({
            title: message,
            icon: "success",
          });

          navigate(`${SALE_TARGET.BASE_PATH}all-target`, {
            state: {
              reload: true,
            },
          });
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [updateSaleTarget, isSuccess, Toast, navigate, saleTargetData]
  );
  const handleExpense = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allow_expense_percentage_value: any = event.target.value;
    console.log("total_sales_target_amount");
    let total_sales_target_amount = getValues().total_sales_target_amount;
    const expense_amount =
      (parseInt(allow_expense_percentage_value) / 100) *
      parseInt(total_sales_target_amount);
    setValue("expense_amount", expense_amount);
  };
  useEffect(() => {
    refetch();
  }, [search, page, limit, refetch]);
  useEffect(() => {
    if (saleTargetData) {
      // Assuming saleTargetData.contains the fields you need
      setAssignName(saleTargetData.username);
      setValue("start_date", saleTargetData.start_date);
      setValue("end_date", saleTargetData.end_date);
      setValue("region_name", saleTargetData.region_name);
      setValue(
        "total_sales_target_amount",
        saleTargetData.total_sales_target_amount
      );
      setValue("expense_amount", saleTargetData.expense_amount);
      setValue(
        "allow_expense_percentage",
        saleTargetData.allow_expense_percentage
      );
      setValue("remark", saleTargetData.remark);
      setValue("description", saleTargetData.description);
      setValue("user_region_id", saleTargetData.user_region_id);
    }
  }, [saleTargetData, setValue, setAssignName]);
  return (
    <PageWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-6 gap-y-6">
          <div className="flex flex-col p-2" style={{ gridColumn: "span 2" }}>
            <label className="mb-2 text-gray-700 capitalize">
              Assignee Name
            </label>
            <label
              onClick={handleModalOpen}
              className="relative block text-gray-400 cursor-pointer focus-within:text-gray-600"
            >
              <input
                type="text"
                name="Search"
                id="search"
                className="px-2 py-3 w-full border-[0.5px] focus:ring-1 ring-primary focus:outline-none rounded border-primary-light cursor-pointer"
                placeholder="Choose Assignee Name"
                value={assignName}
                readOnly
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="currentColor"
                className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
              >
                <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"></path>
              </svg>
            </label>
          </div>
          <CustomDateField
            key="start_date"
            colSpan={2}
            errors={errors}
            name="start_date"
            register={register}
            control={control}
            label="Start Date"
          />
          <CustomDateField
            key="end_date"
            colSpan={2}
            errors={errors}
            name="end_date"
            register={register}
            control={control}
            label="End Date"
          />
          <CustomInputField
            errors={errors}
            colSpan={2}
            key="region_name"
            placeHolder=""
            value={regionList}
            disabled
            register={register}
            name="region_name"
            inputType="text"
            label="Region Name"
          />
          <CustomInputField
            errors={errors}
            colSpan={2}
            key="total_sales_target_amount"
            placeHolder="Enter Total Sale Target Amount"
            register={register}
            name="total_sales_target_amount"
            inputType="text"
            label="Total Sale Target Amount"
            disabled
          />
          {/* <CustomInputField
            errors={errors}
            colSpan={2}
            key="allow_expense_percentage"
            placeHolder="Enter Expense Percentage"
            register={register}
            name="allow_expense_percentage"
            inputType="text"
            label="Expense Percentage"
          /> */}
          <div
            className="undefined flex flex-col p-2 undefined"
            style={{ gridColumn: " auto / span 2" }}
          >
            <label
              className="mb-2 text-gray-700"
              style={{ textTransform: "capitalize" }}
            >
              Expense Percentage
            </label>
            <input
              {...register("allow_expense_percentage")}
              type="text"
              className="px-2 py-3 border-[0.7px] focus:ring-1   ring-primary focus:outline-none rounded border-primary-light"
              placeholder="Enter Expense Percentage"
              onChange={handleExpense}
            />
          </div>

          <CustomInputField
            errors={errors}
            colSpan={2}
            key="expense_amount"
            placeHolder="Enter Expense Amount"
            register={register}
            name="expense_amount"
            inputType="text"
            label="Expense Amount"
            disabled
          />
        </div>
        <div className="grid grid-cols-2 my-10">
          <div className="flex flex-col p-2">
            <label className="mb-2 text-gray-700 capitalize" htmlFor="remark">
              Remark
            </label>
            <textarea
              {...register("remark")}
              rows={3}
              className="px-2 py-3 border-[0.7px] focus:ring-1 ring-primary focus:outline-none rounded border-primary-light"
              placeholder="Enter remark"
            ></textarea>
          </div>
          <div className="flex flex-col p-2">
            <label
              className="mb-2 text-gray-700 capitalize"
              htmlFor="description"
            >
              Description
            </label>
            <textarea
              {...register("description")}
              rows={3}
              className="px-2 py-3 border-[0.7px] focus:ring-1 ring-primary focus:outline-none rounded border-primary-light"
              placeholder="Enter description"
            ></textarea>
          </div>
        </div>
        <div className="ml-2 mt-14">
          <button
            type="button"
            className="text-gray-800 px-28 py-4 rounded text-sm outline outline-0.5 outline-gray-300 mr-10"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-primary text-white px-28 py-4 rounded text-sm outline outline-0.5 outline-gray-300 mr-10"
            disabled={isSubmitting}
          >
            Submit
          </button>
        </div>
      </form>
      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={isOpen}
        contentLabel="Assignee List"
      >
        <div className="relative h-full">
          <div className="flex justify-end">
            <svg
              onClick={handleModalClose}
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#3e3e3e"
              className="cursor-pointer"
            >
              <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
            </svg>
          </div>
          <div className="py-4 px-14">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-xl font-[600] uppercase text-primary-dark">
                Assignee List
              </h3>
              <div className="flex w-[33%] items-center justify-center">
                <div className="w-full">
                  <label className="relative block text-gray-400 focus-within:text-gray-600">
                    <input
                      onChange={handleSearchChange}
                      type="text"
                      name="Search"
                      id="search"
                      placeholder="Search..."
                      className="block w-full px-4 py-[0.65rem] text-[0.80rem] text-gray-500 placeholder-gray-400 bg-white rounded shadow-sm border-[0.7px] border-primary-light form-input focus:outline-none"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="currentColor"
                      className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                    >
                      <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"></path>
                    </svg>
                  </label>
                </div>
              </div>
            </div>
            <div className="h-[410px] overflow-auto">
              <DataTable
                columns={[
                  {
                    name: "Assignee Name",
                    selector: (row: any) => row.username,
                  },
                  {
                    name: "Action",
                    selector: (row: any) => (
                      <button
                        onClick={() => handleRowClick(row)}
                        className="px-3 py-1 text-sm text-gray-100 rounded bg-primary-dark"
                      >
                        Choose
                      </button>
                    ),
                  },
                ]}
                // @ts-ignore
                data={data?.data}
                striped
                pagination
                paginationServer
                // @ts-ignore
                paginationTotalRows={data?.total}
                progressPending={isFetching}
                noDataComponent="No assignees found"
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
              />
            </div>
          </div>
        </div>
      </Modal>
    </PageWrapper>
  );
};

export default SaleTargetAllEdit;
