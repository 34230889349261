import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageWrapper from "@components/layouts/PageWrapper";
import BackButton from "@components/buttons/BackButton";
import SaleTargetProduct from "../../../components/extra/sale-target/SaleTargetProduct";

function ProductTargetAddPage() {
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <BackButton />
      <h3 className="mb-4 ml-2 text-2xl font-semibold text-primary-dark uppercase">
        Add New Product Sale Target
      </h3>
      <SaleTargetProduct />
    </PageWrapper>
  );
}

export default ProductTargetAddPage;
