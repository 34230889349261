import React from "react";
import Select from "react-select";
import { FieldErrors, Controller, Control } from "react-hook-form";
import { isNumeric } from "../../utils/general/isNumeric";

interface CustomMultiSelectFieldProps {
  name: string;
  options: any[] | undefined;
  label?: string;
  value?: any[];
  noLabel?: boolean;
  defaultValue?: any[];
  control: Control;
  errors: FieldErrors<any>;
  disabled?: boolean;
  colSpan?: number;
  required?: boolean;
  classNames?: string;
}

const CustomMultiSelectField: React.FC<CustomMultiSelectFieldProps> = ({
  name,
  options,
  label,
  value,
  noLabel,
  defaultValue,
  control,
  errors,
  disabled,
  colSpan,
  required,
  classNames,
}) => {
  const targetOptions = options?.filter((option) =>
    value?.includes(option.value)
  );

  if (!options) {
    return (
      <div
        style={{
          gridColumn:
            colSpan !== 6 ? `auto / span ${colSpan || "2"}` : "1 / -1",
        }}
        className={`flex flex-col p-2 ${classNames}`}
      >
        <label
          className="mb-2 text-gray-700"
          style={{ textTransform: "capitalize" }}
          htmlFor="name"
        >
          {label || name}
        </label>
        <Select
          menuPortalTarget={document.body}
          isDisabled={disabled}
          styles={{
            control: (provided) => ({
              ...provided,
              paddingTop: "0.19rem",
              paddingBottom: "0.19rem",
              borderColor: "rgb(128,149,187)",
              borderWidth: "0.5px",
            }),
            option: (base, { data, isDisabled, isFocused, isSelected }) => ({
              ...base,
              backgroundColor: isSelected
                ? "rgb(45,120,219)"
                : isFocused
                ? "#e5e7eb"
                : "",
              color: isSelected ? "#ffffff" : "",
            }),
          }}
          options={[]}
          isMulti
        />
      </div>
    );
  }

  return (
    <div
      style={{
        gridColumn: colSpan !== 6 ? `auto / span ${colSpan || "2"}` : "1 / -1",
      }}
      className={`flex flex-col p-2 ${classNames}`}
    >
      {!noLabel ? (
        <label
          className="mb-2 text-gray-700"
          style={{ textTransform: "capitalize" }}
          htmlFor="name"
        >
          {label || name}
          {required ? (
            <span className="ml-[0.15rem] text-red-600 text-md">&#42;</span>
          ) : null}
        </label>
      ) : null}

      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            menuPortalTarget={document.body}
            isDisabled={disabled}
            isMulti
            styles={{
              control: (provided) => ({
                ...provided,
                paddingTop: "0.19rem",
                paddingBottom: "0.19rem",
                borderColor: "rgb(128,149,187)",
                borderWidth: "0.5px",
              }),
              option: (base, { isFocused, isSelected }) => ({
                ...base,
                backgroundColor: isSelected
                  ? "rgb(45,120,219)"
                  : isFocused
                  ? "#e5e7eb"
                  : "",
                color: isSelected ? "#ffffff" : "",
              }),
            }}
            inputId={name}
            value={
              options?.filter((option) => value?.includes(option.value)) || []
            } // value should be an array of selected options
            onBlur={onBlur}
            onChange={(val) => {
              const selectedValues = val
                ? val.map((option) => option.value)
                : [];
              console.log("Selected", selectedValues);
              onChange(selectedValues);
            }}
            options={options}
          />
        )}
      />

      {errors[name] && (
        <div className="mt-2 font-[300] italic text-red-600">
          {/* @ts-ignore */}
          {errors[name].message}
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelectField;
