import { Link, useLocation, useNavigate } from "react-router-dom";
import mainModules from "../../services/main-modules";
import SidebarItem from "@components/extra/layouts/SidebarItem";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@states/store";
import { logOut } from "@states/auth/auth.slice";
import { useGetUserRoleAssignByIdQuery } from "@states/user-management/user-role-assign.api";
import Skeleton from "react-loading-skeleton";
import "animate.css";
import { mainModuleIconFunctions } from "@services/main-module-icon-funcs";

const Sidebar = () => {
  let { pathname } = useLocation();
  const [routedPath, setRoutedPath] = useState(pathname);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("dms-user") || "{}");
  const [userModules, setUserModules] = useState<any[]>([]);

  const { data, isLoading } = useGetUserRoleAssignByIdQuery({
    id: user.user_id,
  });

  useEffect(() => {
    //@ts-ignore
    // if (data && data.data && data.data[0] && data.data[0].moduleData) {
    //     //@ts-ignore
    //     const moduleResults = data.data[0].moduleData.map((m) => {
    //         const mainModule = mainModules.find(
    //             (mm) => mm.name === m.module_name
    //         );
    //         const transformed = {
    //             ...m,
    //             name: m.module_name,
    //             path: mainModule?.path,
    //             subModules: m.sub_modules.map((sm: any) => {
    //                 const subModule = mainModule?.subModules?.find(
    //                     (smo) => smo.name === sm.sub_module_name
    //                 );
    //                 const tranformedSubModules = {
    //                     ...sm,
    //                     name: sm.sub_module_name,
    //                     path: subModule?.path || '/',
    //                 };
    //                 return tranformedSubModules;
    //             }),
    //             //@ts-ignore
    //             renderIcon: mainModule?.renderIcon,
    //         };
    //         return transformed;
    //     });
    //     console.log(moduleResults, 'moduleResults');
    //     setUserModules(moduleResults);
    // }
    //@ts-ignore
    if (!data?.data) return;
    const modulesMap = new Map();
    //@ts-ignore
    data.data.forEach((item) => {
      const moduleId = item.module_id;
      const moduleName = item.module_name;
      const subModuleId = item.sub_module_id;
      const subModuleName = item.sub_module_name;
      const mainModule = mainModules.find((mm) => mm.name === moduleName);
      if (!mainModule?.subModules) {
        modulesMap.set(moduleId, {
          id: moduleId,
          name: moduleName,
          renderIcon: mainModule?.renderIcon,
          path: mainModule?.path,
        });
      } else {
        const subModule = mainModule?.subModules?.find(
          (sm) => sm.name === subModuleName
        );
        if (mainModule && subModule) {
          if (!modulesMap.has(moduleId)) {
            modulesMap.set(moduleId, {
              //@ts-ignore
              id: mainModule.id || moduleId,
              name: moduleName,
              renderIcon: mainModule.renderIcon,
              path: mainModule.path,
              subModules: new Map([[subModuleId, subModule]]),
            });
          } else {
            const existingModule = modulesMap.get(moduleId);
            existingModule?.subModules?.set(subModuleId, subModule);
          }
        }
      }
    });
    const result = Array.from(modulesMap.values())
      .sort((a: any, b: any) => a.id - b.id)
      .map((module) => {
        const changed = {
          ...module,
        };
        if (module.subModules) {
          changed.subModules = Array.from(module.subModules.values()).sort(
            (a: any, b: any) => a.id - b.id
          );
        }
        return changed;
      });
    setUserModules(result);
  }, [data, mainModules]);

  const handleLogOut = () => {
    dispatch(logOut());

    navigate("/login");
  };

  return (
    <div className="w-[17%] bg-white text-gray-500 h-screen px-2">
      <div className="pt-16 pb-8">
        <h4>
          <Link to={"/"}>
            <div className="flex items-center">
              <svg
                className="ml-2"
                width="25"
                height="25"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_1952_145982"
                  //@ts-ignore
                  // style="mask-type:luminance"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="32"
                  height="32"
                >
                  <rect width="32" height="32" fill="white" />
                </mask>
                <g mask="url(#mask0_1952_145982)">
                  <path
                    d="M13.6702 7.84619L14.0414 13.3661L14.2257 16.1405C14.2277 16.4259 14.2723 16.7093 14.3586 16.9817C14.5811 17.5105 15.1166 17.8465 15.6991 17.8231L24.5754 17.2424C24.9598 17.2361 25.331 17.3799 25.6073 17.6421C25.8375 17.8607 25.9862 18.1465 26.0331 18.454L26.0489 18.6407C25.6815 23.7269 21.9459 27.9693 16.8702 29.0644C11.7944 30.1595 6.58947 27.8461 4.08127 23.3803C3.35818 22.0828 2.90652 20.6568 2.75283 19.1857C2.68863 18.7503 2.66036 18.3104 2.6683 17.8704C2.66036 12.4174 6.54362 7.70302 11.9794 6.5665C12.6337 6.46462 13.275 6.81096 13.5374 7.40778C13.6053 7.54599 13.6501 7.69402 13.6702 7.84619Z"
                    fill="#737791"
                  />
                  <path
                    opacity="0.4"
                    d="M29.3337 13.0824L29.3244 13.1259L29.2975 13.189L29.3012 13.3625C29.2873 13.5923 29.1985 13.8133 29.0457 13.992C28.8864 14.178 28.6688 14.3047 28.4292 14.3539L28.2831 14.3739L18.042 15.0375C17.7013 15.0711 17.3621 14.9612 17.1089 14.7353C16.8977 14.5469 16.7628 14.2928 16.7247 14.0189L16.0373 3.79276C16.0253 3.75819 16.0253 3.72071 16.0373 3.68612C16.0467 3.40424 16.1708 3.1378 16.3819 2.94632C16.5928 2.75484 16.8733 2.65428 17.1604 2.66711C23.2403 2.82179 28.3501 7.19374 29.3337 13.0824Z"
                    fill="#737791"
                  />
                </g>
              </svg>

              <span className="ml-3">Dashboard</span>
            </div>
          </Link>
        </h4>
      </div>
      <hr />
      <div>
        <h6 className="mt-4 mb-6 text-xs text-gray-400 uppercase">
          Management Menu
        </h6>
        {isLoading ? (
          <>
            <Skeleton className="w-full h-10 mb-5 animate__animated animate__fadeOut" />
            <Skeleton className="w-full h-10 mb-5 animate__animated animate__fadeOut" />
            <Skeleton className="w-full h-10 mb-5 animate__animated animate__fadeOut" />
            <Skeleton className="w-full h-10 mb-5 animate__animated animate__fadeOut" />
            <Skeleton className="w-full h-10 mb-5 animate__animated animate__fadeOut" />
            <Skeleton className="w-full h-10 mb-5 animate__animated animate__fadeOut" />
            <Skeleton className="w-full h-10 mb-5 animate__animated animate__fadeOut" />
            <Skeleton className="w-full h-10 mb-5 animate__animated animate__fadeOut" />
            <Skeleton className="w-full h-10 mb-5 animate__animated animate__fadeOut" />
            <Skeleton className="w-full h-10 mb-5 animate__animated animate__fadeOut" />
          </>
        ) : null}
        {userModules.map((mainModule) => (
          <SidebarItem
            routedPath={routedPath}
            setRoutedPath={setRoutedPath}
            key={mainModule.id}
            mainModule={mainModule}
          />
        ))}
      </div>
      {!isLoading && (
        <div className="flex items-center justify-center pb-8 animate__animated animate__fadeIn">
          <button
            onClick={handleLogOut}
            className="outline w-[90%] outline-[0.5px] flex items-center justify-center p-2 mt-10 rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="21px"
              viewBox="0 -960 960 960"
              width="21px"
              fill="#4b5563"
              className="mr-2"
            >
              <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" />
            </svg>
            Sign out
          </button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
