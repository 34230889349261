import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { STOCK_BALANCE } from "@utils/constants/warehouse-and-inventory/stock-balance.constants";

export const stockBalanceApi = createApi({
  reducerPath: "stockBalanceApi",
  tagTypes: ["StockBalance"],
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
  }),
  endpoints: (builder) => ({
    getAllStockBalances: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        product_id?: string | number;
        product_category_id?: string | number;
        product_tag_id?: string | number;
      }
    >({
      query: ({
        page = 1,
        limit = 100,
        product_id,
        product_category_id,
        product_tag_id,
      }) => {
        const queryParams = {
          url: STOCK_BALANCE.GET_ALL,
          params: { page, limit: 100 },
        };

        if (product_id) {
          //@ts-ignore
          queryParams.params.product_id = product_id;
        }

        if (product_category_id) {
          //@ts-ignore
          queryParams.params.product_category_id = product_category_id;
        }

        if (product_tag_id) {
          //@ts-ignore
          queryParams.params.product_tag_id = product_tag_id;
        }

        return queryParams;
      },
    }),
  }),
});

export const { useGetAllStockBalancesQuery } = stockBalanceApi;
