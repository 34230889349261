import OutlinedStatus from "@components/status/OutlinedStatus";
import { thousandSeperator } from "@utils/general/digit-separators";
import { formatDate } from "@utils/general/format-date";

export const salesByRegionReportColumns = () => [
  {
    name: "No",
    selector: (row: any) => (!isNaN(row.no) ? row.no + 1 : row.no),
    width: "15ch",
  },
  //   {
  //     name: "Date",
  //     selector: (row: any) =>
  //       row.no !== "Grand Total:" && (
  //         <div className=" text-primary-dark">
  //           {row.invoice_date ? formatDate(new Date(row.invoice_date)) : ""}
  //         </div>
  //       ),
  //   },

  {
    name: "Region ID",
    selector: (row: any) => row.no !== "Grand Total:" && row.region_code,
  },

  {
    name: "Region Name",
    selector: (row: any) =>
      row.no !== "Grand Total:" && <div className="">{row.region_name}</div>,
  },

  //   {
  //     name: "NO of PO",
  //     selector: (row: any) =>
  //       row.no !== "Grand Total:" && thousandSeperator(row.po_count),
  //   },

  {
    name: "Primary Sales Amount",
    selector: (row: any) => thousandSeperator(row.primary_sale_amount || 0),
  },

  {
    name: "Secondary Sales Amount",
    selector: (row: any) => thousandSeperator(row.secondary_sale_amount || 0),
  },

  {
    name: "Direct Sales Amount",
    selector: (row: any) => thousandSeperator(row.direct_sale_amount || 0),
  },

  {
    name: "Total Sales Amount",
    selector: (row: any) => thousandSeperator(row.total_amount || 0),
  },
];
