import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { PRODUCT } from "@utils/constants/product.constants";

export const productApi = createApi({
  reducerPath: "productApi",
  tagTypes: ["Product"],
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
  }),
  endpoints: (builder) => ({
    getAllProducts: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        product_name?: string;
        product_category_id?: string | number;
        product_category_ids?: string | number;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        product_name,
        product_category_id,
        product_category_ids,
      }) => {
        const queryParams = {
          url: PRODUCT.GET_ALL,
          params: { page, limit },
        };

        if (product_name) {
          //@ts-ignore
          queryParams.params.product_name = product_name;
        }
        if (product_category_id) {
          //@ts-ignore
          queryParams.params.product_category_id = product_category_id;
        }
        if (product_category_ids) {
          //@ts-ignore
          queryParams.params.product_category_ids = product_category_ids;
        }

        return queryParams;
      },
    }),
    getProductsByCategories: builder.mutation<
      [],
      {
        page?: number;
        limit?: number;
        body?: any;
      }
    >({
      query: ({ page = 1, limit = 10, body }) => {
        const queryParams = {
          url: PRODUCT.GET_BY_CATEGORIES,
          params: { page, limit },
          method: "POST",
          body: {
            categories: body,
          },
        };

        return queryParams;
      },
    }),
    getProduct: builder.query<[], { id: string | number }>({
      query: ({ id }) => `${PRODUCT.EDIT}${id}`,
    }),
    getProductByBarcode: builder.query<[], { barcode: string | number }>({
      query: ({ barcode }) => `${PRODUCT.GET_BY_BARCODE}${barcode}`,
    }),
    createProduct: builder.mutation({
      query: (body) => {
        const { product_photo, ...res } = body;

        const formData = new FormData();

        formData.append("product_photo", product_photo[0]);

        for (const [key, value] of Object.entries(res)) {
          //@ts-ignore
          formData.append(key, value);
        }

        //@ts-ignore
        for (const pair of formData.entries()) {
          console.log(pair[0], pair[1]);
        }

        return {
          url: PRODUCT.CREATE,
          method: "POST",
          body,
          formData: true,
        };
      },
    }),
    updateProduct: builder.mutation({
      query: ({ id, body }) => ({
        url: `${PRODUCT.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `${PRODUCT.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetProductsByCategoriesMutation,
  useDeleteProductMutation,
  useGetProductQuery,
  useGetProductByBarcodeQuery,
  useLazyGetProductByBarcodeQuery,
  useLazyGetAllProductsQuery,
} = productApi;
