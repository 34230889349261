import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";

export const reportApi = createApi({
  reducerPath: "reportApi",
  tagTypes: ["Report"],
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
  }),
  endpoints: (builder) => ({
    getSalesByProduct: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({ from_date, to_date }) => {
        const queryParams = {
          url: "reports/sales-by-products",
          params: {},
        };

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),

    getSalesByCustomer: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({ from_date, to_date }) => {
        const queryParams = {
          url: "reports/sales-by-customer",
          params: {},
        };

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),

    getSalesByCustomerCategory: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({ from_date, to_date }) => {
        const queryParams = {
          url: "reports/sales-by-customer-category",
          params: {},
        };

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),
    getSalesByProductCategory: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({ from_date, to_date }) => {
        const queryParams = {
          url: "reports/sales-by-product-categories",
        };

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),

    getStockTransferReport: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
        warehouse_id?: string;
        inventory_transfer_method?: string;
      }
    >({
      query: ({
        from_date,
        to_date,
        warehouse_id,
        inventory_transfer_method,
      }) => {
        const queryParams = {
          url: "reports/stock-transfer",
          params: {},
        };

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        if (inventory_transfer_method) {
          //@ts-ignore
          queryParams.params.inventory_transfer_method =
            inventory_transfer_method;
        }

        if (warehouse_id) {
          //@ts-ignore
          queryParams.params.warehouse_id = warehouse_id;
        }
        return queryParams;
      },
    }),

    getStockBalanceReport: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        warehouse_id?: string;
      }
    >({
      query: ({ warehouse_id }) => {
        const queryParams = {
          url: "reports/stock-balance",
          params: {},
        };
        if (warehouse_id) {
          //@ts-ignore
          queryParams.params.warehouse_id = warehouse_id;
        }
        return queryParams;
      },
    }),

    getStockAdjustmentReport: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
        warehouse_id?: string;
        adjustment_method?: string;
      }
    >({
      query: ({ from_date, to_date, warehouse_id, adjustment_method }) => {
        const queryParams = {
          url: "reports/stock-adjustment",
          params: {},
        };

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        if (adjustment_method) {
          //@ts-ignore
          queryParams.params.adjustment_method = adjustment_method;
        }

        if (warehouse_id) {
          //@ts-ignore
          queryParams.params.warehouse_id = warehouse_id;
        }
        return queryParams;
      },
    }),

    getPurchaseOrderBySupplier: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({ from_date, to_date }) => {
        const queryParams = {
          url: "reports/purchase-order-by-supplier",
          params: {},
        };

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),

    getPurchaseOrderDetail: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({ from_date, to_date }) => {
        const queryParams = {
          url: "reports/purchase-order-detail",
          params: {},
        };

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),

    getSalesDetail: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        sales_type?: string;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({ sales_type, from_date, to_date }) => {
        const queryParams = {
          url: "reports/sales-detail",
          params: {},
        };

        if (sales_type) {
          //@ts-ignore
          queryParams.params.sales_type = sales_type;
        }

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),
    getSalesByRegion: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({ from_date, to_date }) => {
        const queryParams = {
          url: "reports/sales-by-region",
          params: {},
        };

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),

    getStockImportReport: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string | number;
        to_date?: string | number;
        warehouse_id?: string;
      }
    >({
      query: ({ from_date, to_date, warehouse_id }) => {
        const queryParams = {
          url: "reports/stock-import",
          params: {},
        };
        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }
        if (warehouse_id) {
          //@ts-ignore
          queryParams.params.warehouse_id = warehouse_id;
        }
        return queryParams;
      },
    }),
  }),
});

export const {
  useGetSalesByProductQuery,
  useGetSalesByCustomerQuery,
  useGetSalesByProductCategoryQuery,
  useGetStockTransferReportQuery,
  useGetStockBalanceReportQuery,
  useGetStockAdjustmentReportQuery,
  useGetPurchaseOrderBySupplierQuery,
  useGetPurchaseOrderDetailQuery,
  useGetSalesDetailQuery,
  useGetSalesByRegionQuery,
  useGetStockImportReportQuery,
  useGetSalesByCustomerCategoryQuery,
} = reportApi;
