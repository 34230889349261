import React, { useEffect, useMemo, useRef } from "react";
import DataTable from "react-data-table-component";
import PageWrapper from "@components/layouts/PageWrapper";
import { useLocation } from "react-router-dom";

import TableSkeleton from "@components/layouts/TableSkeleton";

import NoRecord from "@components/layouts/NoRecord";
import { useGetSalesByRegionQuery } from "@states/report/report.api";
import { RiFileExcel2Line } from "react-icons/ri";
import { salesByRegionReportColumns } from "@helpers/columns/report/sales-by-region-report.columns";
import SalesByRegionReportFilter from "@components/filters/SalesByRegionReportFilter";

const SalesByRegionPage = ({ handleErrors }: any) => {
  const filterRef = useRef({
    from_date: undefined,
    to_date: undefined,
  });

  const location = useLocation();

  const {
    data,
    isFetching,
    error: getAllError,
    refetch,
  } = useGetSalesByRegionQuery({
    ...filterRef.current,
  });

  if (handleErrors) {
    handleErrors(getAllError);
  }

  useEffect(() => {
    if (location.state?.reload) {
      refetch();
    }
  }, [location.state?.reload]);

  const okayData = useMemo(
    () =>
      //@ts-ignore
      data?.data?.map((d, i) => ({
        ...d,
        no: i,
      })),
    [data]
  );

  console.log("Ok", okayData);

  return (
    <React.Fragment>
      <PageWrapper title={"Sales By Region Report"}>
        <div className="flex justify-between items-center">
          <div className="w-3/4">
            <SalesByRegionReportFilter
              filterRef={filterRef}
              refetch={refetch}
            />
          </div>
          <div className="flex  justify-center items-center rounded-md shadow-sm cursor-pointer gap-2 py-2 px-6 bg-red-600 text-white">
            <RiFileExcel2Line />
            <p>Export Excel</p>
          </div>
        </div>
        <DataTable
          responsive
          striped
          //   pagination // //@ts-ignore
          progressPending={isFetching}
          noDataComponent={<NoRecord />}
          progressComponent={<TableSkeleton row={10} col={7} />}
          conditionalRowStyles={[
            {
              when: (row) => row.no === "Total:",
              style: {
                backgroundColor: "#F1F2F5",
                fontWeight: 450,
              },
            },
          ]}
          //@ts-ignore
          columns={salesByRegionReportColumns()}
          //@ts-ignore
          data={[
            ...(okayData ?? []),
            {
              no: "Grand Total:",
              primary_sale_amount:
                (okayData &&
                  okayData.reduce((acc: any, cur: any) => {
                    const primary_sale_amount = Number(cur.primary_sale_amount);
                    return (
                      acc +
                      (isNaN(primary_sale_amount) ? 0 : primary_sale_amount)
                    );
                  }, 0)) ||
                0,
              secondary_sale_amount:
                (okayData &&
                  okayData.reduce((acc: any, cur: any) => {
                    const secondary_sale_amount = Number(
                      cur.secondary_sale_amount
                    );
                    return (
                      acc +
                      (isNaN(secondary_sale_amount) ? 0 : secondary_sale_amount)
                    );
                  }, 0)) ||
                0,
              direct_sale_amount:
                (okayData &&
                  okayData.reduce((acc: any, cur: any) => {
                    const direct_sale_amount = Number(cur.direct_sale_amount);
                    return (
                      acc + (isNaN(direct_sale_amount) ? 0 : direct_sale_amount)
                    );
                  }, 0)) ||
                0,

              total_amount:
                (okayData &&
                  okayData.reduce((acc: any, cur: any) => {
                    const total_amount = Number(cur.total_amount);
                    return acc + (isNaN(total_amount) ? 0 : total_amount);
                  }, 0)) ||
                0,
              noAction: true,
            },
          ]}
        />
      </PageWrapper>
    </React.Fragment>
  );
};

export default SalesByRegionPage;
